<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button-group.tab-group
        b-button.mr-2(variant="primary" :class="{off: statusTab !== 0}" @click="changeStatusBar(0)") 待推播
        b-button.mr-2(variant="primary" :class="{off: statusTab !== 2}" @click="changeStatusBar(2)") 處理中
        b-button.mr-2(variant="primary" :class="{off: statusTab !== 1}" @click="changeStatusBar(1)") 已推播
        b-button.mr-2(variant="primary" :class="{off: statusTab !== 3}" @click="changeStatusBar(3)") 已取消
    .col-lg-12()

      //- 表格
      .card
        .card-body
          .header-row.mb-3
            .header-title 推播排程列表
            b-button.width-md(variant="primary" to="/line-broadcast/editor/create" )
              span 新增  

          //- Table
          .table-responsive.mb-0
            b-table(
              responsive, 
              ref="broadCastListTable",
              :items="getBroadcastList", 
              :fields="fields", 
              :per-page="perPage", 
              :current-page="currentPage", 
            )

              //- 項次
              template(v-slot:cell(index)="row") {{ row.index + 1 }}

              //- 任務內容
              template(v-slot:cell(body)="row") {{ row.item.body.substring(0,15) }}...

              //- 任務狀態
              template(v-slot:cell(status)="row")
                b-badge( variant="secondary" pill v-if="row.item.status == 0") 等待發送
                b-badge( variant="primary" pill v-if="row.item.status == 1") 已經發送
                b-badge( variant="warning" pill v-if="row.item.status == 2") 正在處理
                b-badge( variant="dark" pill v-if="row.item.status == 3") 已經取消

              //- 操作
              template(v-slot:cell(edit)="row")
                b-button.table-btn.mr-1(variant="primary" :to="'/line-broadcast/editor/' + row.item.id" )
                  span(v-if="row.item.status <= 1") 編輯
                  span(v-else) 查看
                b-button.ml-0(variant="danger",size="md",v-if="row.item.status <= 1",@click="cancelItem(row.item.id)")
                  i.fe-trash-2 

          .row.mb-md-2
            .col-12
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  name: 'BraodCastList',
  data () {
    return {
      title: "Line 推播設定",
      items: [{
        text: "Line 推播管理",
        href: "/notification",
      },
      {
        text: "Line 推播設定",
        active: true,
      }
      ],
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortDesc: false,
      status: false,
      fields: [
        { key: 'index', label: "項次", class: 'width-auto center' },
        { key: 'title', label: "任務名稱", class: 'width-auto center' },
        { key: "schedule_time", label: "推送時間", class: 'width-auto center' },
        { key: "real_audience_count", label: "用戶裝置數", class: 'width-auto center' },
        { key: "status", label: "目前狀態", class: 'width-auto center' },
        { key: "edit", label: "操作", class: 'width-auto center' }
      ],
      statusTab: 0
    };
  },
  methods: {
    // 變更狀態夾
    changeStatusBar (_status) {
      this.statusTab = _status
      this.currentPage = 1
      this.reloadBroadCastList()
    },
    reloadBroadCastList () {
      this.$refs.broadCastListTable.refresh()
    },
    // 獲得推播項目清單
    getBroadcastList (_table, _callback) {
      let vm = this
      this.$root.apis.getLineBroadcastList({
        status_type: this.statusTab,
        page: this.currentPage
      }, function (_response) {
        let response = _response.body.data
        vm.currentPage = response.page
        vm.totalRows = response.total
        vm.perPage = response.per_page
        _callback(response.items)
      })
    },
    // 請求取消推播項目
    cancelItem (_broadcastItemId) {
      let vm = this
      this.$root.common.confirmAction("取消推播", "您正在嘗試取消這個 Line 推播項目，確定要取消嗎？", "對，取消推播", "先不要",
        function () {
          vm.$root.apis.cancelLineBroadcastItem(_broadcastItemId, function () {
            vm.reloadBroadCastList()
            vm.$root.common.showSingleNotify("推播項目已經取消成功！")
          })
        }
      )
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  }
};
</script>

<style lang="sass" scoped>
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)    
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center
  
</style>
